<template>
  <div id="not-found" class="flex flex-col justify-center items-center h-full">
    <router-link :to="{ path: '/' }">
      <Logo v-if="showLogo" class="mt-10 md:hidden" color="pink" />
    </router-link>
    <div class="flex-grow flex flex-col justify-center items-center w-64">
      <div
        class="w-60 h-60 bg-gray-300 rounded-full overflow-hidden flex justify-center items-center"
      >
        <img
          class="w-40 h-40 rounded-full"
          src="@/assets/img/icons/default-avatar.svg"
        />
      </div>
      <h3 class="mt-4">
        {{ title }}
      </h3>
      <p class="font-body2 mt-4" v-if="description != ''">
        {{ description }}
      </p>
    </div>
  </div>
</template>
<script>
import Logo from "@/components/Logo.vue";

export default {
  components: {
    Logo
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      Type: String,
      default: ""
    },
    showLogo: {
      type: Boolean,
      default: true
    }
  },
  head: {
    meta: [
      {
        id: "prerender-status-code",
        name: "prerender-status-code",
        content: "404"
      }
    ]
  }
};
</script>
