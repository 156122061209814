import gql from "graphql-tag";
import { hardcodedProfileFields } from "@/kentico-api/sharedSnippets.js";

export const KENTICO_GET_SUPPORTER = gql`
  query supporter($codename: String!) {
    supporter(codename: $codename) {
      ${hardcodedProfileFields}
    }
  }
`;
