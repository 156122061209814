import gql from "graphql-tag";
import { hardcodedProfileFields } from "@/kentico-api/sharedSnippets.js";

export const KENTICO_GET_CAPTAIN = gql`
  query captain($codename: String!) {
    captain(codename: $codename) {
      ${hardcodedProfileFields}
    }
  }
`;
