<template>
  <div class="h-full">
    <template v-if="profile">
      <div class="md:mt-8">
        <BackButton
          v-if="isDesktop"
          @click="$router.go(-1)"
          class="mb-8"
          type="dark"
          text="Back"
        />
        <div
          class="w-full relative bg-cover bg-center md:bg-white flex flex-col justify-center items-center md:rounded-t-md"
        >
          <img :src="profileBgImage" alt="profile-background" class="w-full" />
          <div class="bg-controls"></div>
          <BackButton
            v-if="!isDesktop"
            @click="$router.go(-1)"
            class="absolute left-4 top-12 visible md:invisible"
          />
          <img
            :src="profileAvatarImage"
            alt="Profile image"
            class="profile-img h-36 w-36 bg-white border-8 border-white rounded-full object-cover"
          />
        </div>
        <div class="md:bg-white flex flex-col items-center pb-6">
          <div class="sm:w-3/5 md:w-2/5 px-4">
            <h2 class="pt-4 text-center mb-2">{{ profile.name }}</h2>
            <p
              class="text-sm text-center mb-8 md:mb-24 kentico-content"
              v-html="profile.description.html"
            ></p>
            <div v-if="profile._supportedTeam">
              <label for="" class="block text-base mb-2"
                >Proudly Supporting</label
              >
              <img
                :src="profile._supportedTeam.image.url"
                alt=""
                class="cursor-pointer rounded-md"
                @click="
                  $router.push({
                    name: 'TeamPage',
                    params: { slug: profile._supportedTeam.teamSlug }
                  })
                "
              />
            </div>
          </div>
        </div>
        <SocialShareGeneric
          class="my-8"
          :url="captainUrl"
          :shareText="socialShareText"
          :hashtags="socialShareHashtags"
          :data="shareData"
          type="captain-profile"
        />
      </div>
    </template>
    <template v-else-if="loading">
      <HardcodedProfilePageSkeleton />
    </template>
    <template v-else>
      <div class="w-full h-full flex flex-col relative md:pt-6">
        <NotFound
          :title="$t('message.profile-not-found')"
          :description="$t('message.profile-not-found-description')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { KENTICO_GET_SUPPORTER } from "@/kentico-api/supporters.js";
import { KENTICO_GET_CAPTAIN } from "@/kentico-api/captains.js";
import { seoMetadata } from "@/utils/seoMetadata";
import HardcodedProfilePageSkeleton from "@/components/skeletons/HardcodedProfilePageSkeleton.vue";
import NotFound from "@/components/shared/NotFound";
import BackButton from "@/components/shared/BackButton.vue";
import SocialShareGeneric from "@/components/posts/SocialShareGeneric.vue";
export default {
  name: "HardcodedProfilePage",
  components: {
    HardcodedProfilePageSkeleton,
    NotFound,
    BackButton,
    SocialShareGeneric
  },
  props: {
    kenticoCodename: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      loading: true,
      metaData: {
        metaDescription: null,
        metaTitle: null,
        metaImageUrl: null,
        metaVideoUrl: null,
        keywords: null
      },
      pageType: ""
    };
  },
  head: function() {
    return seoMetadata({
      fullPath: this.$route.fullPath,
      metaDescription: this.metaData.metaDescription,
      metaTitle: this.metaData.metaTitle,
      keywords: this.metaData.keywords,
      metaImageUrl: this.metaData.imageUrl,
      metaVideoUrl: this.metaData.videoUrl
    });
  },
  computed: {
    ...mapGetters("general", ["isDesktop"]),
    socialShareText() {
      if (
        this.profile?.profileShareTitle &&
        this.profile.profileShareTitle !== ""
      ) {
        return this.profile.profileShareTitle;
      }

      return this.metaTitle || "";
    },
    socialShareHashtags() {
      if (
        this.profile?.profileShareHashtags &&
        this.profile.profileShareHashtags !== ""
      ) {
        return this.profile.profileShareHashtags;
      }

      return "";
    },
    captainUrl() {
      return process.env.VUE_APP_WEB_URL + this.$route.fullPath;
    },
    profileAvatarImage() {
      if (this.profile?.avatar?.url) return this.profile.avatar.url;
      else return "/img/header-default.svg";
    },
    profileBgImage() {
      if (this.profile?.backgroundImage?.url)
        return this.profile.backgroundImage.url;
      else return "/img/header-default.svg";
    },
    shareData() {
      return {
        profile: {
          id: "pending-id - this.captain.id",
          screenName: "pending-screenname - this.captain.screenname"
        }
      };
    }
  },
  apollo: {
    profile: {
      client: "kenticoClient",
      query() {
        return this.getQueryName();
      },
      update(data) {
        this.loading = false;
        const profile = data.supporter || data.captain;
        const og = profile._openGraph;
        this.metaData.metaTitle = og.ogTitle !== "" ? og.ogTitle : profile.name;
        this.metaData.metaDescription =
          og.ogDescription !== "" ? og.ogDescription : profile.description.html;
        this.metaData.imageUrl =
          og.ogImage?.url && og.ogImage.url !== ""
            ? og.ogImage.url
            : profile.avatar?.url;
        this.$emit("updateHead");
        return profile;
      },
      variables() {
        return {
          // assume slug is the same as kentico codename
          // temporary solution until captains/supporters are no longer hardcoded on cms
          codename: this.$route.params.profileSlug?.replaceAll("-", "_")
        };
      },
      fetchPolicy: "no-cache"
    }
  },
  methods: {
    // get query name
    getQueryName() {
      if (this.$route.name.toLowerCase().includes("supporter")) {
        this.pageType = "supporter";
        return KENTICO_GET_SUPPORTER;
      } else {
        this.pageType = "captain";
        return KENTICO_GET_CAPTAIN;
      }
    },
    slugExists() {
      return this.$route.params.slug;
    }
  }
};
</script>

<style scoped>
.profile-img {
  margin-top: -72px;
  transform: translateY(0);
}
</style>
