<template>
  <div class="w-full flex mx-auto">
    <slot>
      <button
        @click="share()"
        class="btn mx-auto btn-clear w-44 justify-center items-center relative"
      >
        <span class="absolute top-auto button-auto left-4"
          ><img src="@/assets/img/icons/export.svg" alt="export"
        /></span>
        {{ shareButtonText }}
      </button>
    </slot>
    <BaseFlexModal v-model="showShareMenu">
      <div class="w-72">
        <input type="hidden" id="post-url" :value="url" />
        <ul class="options-list w-72 px-6">
          <li
            class="h-16 text-left font-subtitle2 text-blue-900 flex items-center"
          >
            <button
              @click="shareToTwitter(url, shareText, hashtags)"
              class="flex flex-row items-center"
            >
              <img
                class="w-6 mr-4"
                src="@/assets/img/icons/social-blue/twitter.svg"
                alt="twitter"
              />
              <div class="font-subtitle2">
                Twitter
              </div>
            </button>
          </li>
          <li
            class="h-16 text-left font-subtitle2 text-blue-900 flex items-center"
          >
            <button
              @click="shareToFacebook(url, shareText)"
              class="flex flex-row items-center"
            >
              <img
                class="w-6 mr-4"
                src="@/assets/img/icons/social-blue/facebook.svg"
                alt="facebook"
              />
              <div class="font-subtitle2">
                Facebook
              </div>
            </button>
          </li>
          <li
            class="h-16 text-left font-subtitle2 text-blue-900 flex items-center"
          >
            <button class="flex flex-row items-center" @click="copyLink()">
              <img
                class="w-6 mr-4"
                src="@/assets/img/icons/social-blue/dotdotdot.svg"
                alt="link"
              />
              <div class="font-subtitle2">
                {{ $t("message.copy-link") }}
              </div>
            </button>
          </li>
        </ul>
      </div>
    </BaseFlexModal>
  </div>
</template>
<script>
import SocialShareMixin from "@/mixins/SocialShareMixin.js";
export default {
  mixins: [SocialShareMixin],
  props: {
    url: {
      type: String,
      required: true
    },
    shareText: {
      type: String,
      required: true
    },
    shareButtonText: {
      type: String,
      default: "share"
    },
    hashtags: {
      type: String,
      default: ""
    },
    data: {
      type: Object,
      required: true,
      validator: function(value) {
        if (value.profile) {
          if (!value.profile.id || !value.profile.screenName) {
            console.error("profile should include `id` and `screenName`");
            return false;
          }
          return true;
        }
        if (value.team) {
          if (!value.team.slug) {
            console.error("team should include `slug`");
            return false;
          }
          return true;
        }
        if (value.task) {
          if (!value.task.taskId || !value.task.teamSlug) {
            console.error("task should include `taskId` and `teamSlug`");
            return false;
          }
          return true;
        }
        console.error(
          "valid data type not found, the object should contain a nested object `team` or `profile`. You can add a new one if needed but need to update analytics.js too."
        );
        return false;
      }
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showShareMenu: false
    };
  },
  methods: {
    share() {
      this.shareNative(this.url, this.shareText, () => {
        this.showShareMenu = true;
      });
    }
  }
};
</script>
<style lang="postcss" scoped>
.options-list {
  li {
    @apply border-t border-gray-200;
  }

  li:first-child {
    border-top: none;
  }

  li:last-child {
    border-bottom: none;
  }
}
</style>
