const og = `
  _openGraph {
    ogDescription
    ogLocale
    ogTitle
    ogUrl
    ogVideo
    ogImage {
      url
    }
  }
`;

const hardcodedProfileFields = `
  ${og}
  name
  slug
  _system_ {
    codename
  }
  _supportedTeam {
    teamSlug
    image {
      url
    }
  }
  avatar {
    url
  }
  backgroundImage {
    url
  }
  backgroundImageMobile {
    url
  }
  description {
    html
  }
  profileShareTitle
  profileShareHashtags
`;

export { og, hardcodedProfileFields };
